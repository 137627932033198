const router = [
    {
        path: '/lead',
        name: '智能引导',
        component: () => import('@/views/pushLead/lead')
    },
    {
        path: '/push',
        name: '智能推送',
        component: () => import('@/views/pushLead/push')
    },
    {
        path: '/dailyPush',
        name: '每日推送维护',
        component: () => import('@/views/pushLead/push/dailyPush.vue')
    }
]
export default router
