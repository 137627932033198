import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API
})

service.interceptors.request.use(
    config => {
        // 过滤参数中的空值
        if (config.data) {
            for (const key in config.data) {
                if (isEmpty(config.data[key])) {
                    delete config.data[key]
                }
            }
        }
        if (config.params) {
            for (const key in config.params) {
                if (isEmpty(config.params[key])) {
                    delete config.params[key]
                }
            }
        }

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        return Promise.resolve(res)
    },
    error => {
        Message({
            message: error.response.data.message,
            type: 'error',
            duration: 5 * 1000
        })

        return Promise.reject(error)
    }
)

export default service

/**
 * 非空判断
 * @param {*} obj
 * @returns
 */
function isEmpty(obj) {
    return typeof obj === 'undefined' || obj === null || obj === ''
}
