import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import Utils from './utils'

import 'tailwindcss/tailwind.css'
import './style/index.scss'

import './style/element-theme.css'
import './style/element-variables.scss'

Vue.use(Utils)

Vue.config.productionTip = false

import components from '@/components'
Vue.use(components)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
