const router = [
    {
        path: '/safeSystemSearch',
        name: '安全制度搜索',
        component: () => import('@/views/safeSystem/search')
    },
    {
        path: '/safeSystemMaintain',
        name: '法规制度维护',
        component: () => import('@/views/safeSystem/maintain')
    }
]
export default router
