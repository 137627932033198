import Vue from 'vue'
import Axios from './dev-axios'
// import fileDownload from 'js-file-download'

let axios = parent.axios
const isDev = process.env.NODE_ENV === 'development'
if (isDev) {
    axios = Axios
}

// http method
const METHOD = {
    GET: 'get',
    POST: 'post',
    DOWNLOAD: 'download',
    DOWNLOAD_GET: 'downloadGet',
    UPLOAD: 'upload'
}

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @param config
 * @returns {Promise<AxiosResponse<T>>}
 */
function request(url, method, params, config) {
    // 补充斜杠
    if (url.indexOf('/') !== 0) {
        url = '/' + url
    }
    params = params || {}
    switch (method) {
        case METHOD.GET:
            return axios.get(url, { params, ...config })
        case METHOD.POST:
            return axios.post(
                url,
                params,
                Object.assign(
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                    },
                    config
                )
            )
        case METHOD.DOWNLOAD:
            return axios.post(
                url,
                params,
                Object.assign(
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                        },
                        responseType: 'blob'
                    },
                    config
                )
            )
        case METHOD.DOWNLOAD_GET:
            return axios.get(
                url,
                params,
                Object.assign(
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        responseType: 'blob',
                        transformRequest: [
                            function(data) {
                                return data
                            }
                        ]
                    },
                    config
                )
            )
        case METHOD.UPLOAD:
            return axios.post(
                url,
                params,
                Object.assign(
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    },
                    config
                )
            )
        default:
            return axios.get(url, { params, ...config })
    }
}

/**
 * 封装一层，统一处理异常
 */
function doRequest(url, method, params, config, vm) {
    return new Promise((resolve, reject) => {
        request(url, method, params, config)
            .then(async res => {
                // const { request, headers } = res
                // const { data } = res
                // if (request.responseType === 'blob' || request.responseType === 'arraybuffer') {
                //     // 下载调用
                //     if (data.type === 'application/json') {
                //         const str = await readBlob(data)
                //         data = JSON.parse(str)
                //     } else {
                //         // 下载文件
                //         let fileName = headers['content-disposition'].split('=')[1]
                //         fileName = decodeURIComponent(fileName)
                //         await fileDownload(data, fileName)
                //         resolve({})
                //         return
                //     }
                // }
                if (res.code === 200) {
                    resolve(res)
                } else {
                    Vue.prototype.$showError(res.message)
                    reject(res)
                }
            })
            .catch(err => {
                if (isDev) {
                    console.log(err, 'err')
                }
                cleanLoading(vm)
            })
            .finally(() => {
                Vue.prototype.$hideAllLoading()
            })
    })
}

// function readBlob(blob) {
//     return new Promise(resolve => {
//         const reader = new FileReader()
//         reader.onload = e => {
//             // 异常信息处理
//             resolve(e.target.result)
//         }
//         reader.readAsText(blob)
//     })
// }

/**
 * 将页面所有包含“loading”的属性，设置为false
 * @param vm
 */
function cleanLoading(vm) {
    if (vm) {
        for (const field in vm) {
            if (Object.prototype.hasOwnProperty.call(vm, field) && field.toLowerCase().indexOf('loading') !== -1 && typeof vm[field] === 'boolean') {
                vm[field] = false
            }
        }
    }
}

export { METHOD, request, doRequest }
