<template>
    <div>
        <el-dialog :visible.sync="isShow" :title="title" :width="width" :top="top">
            <slot name="content" />
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'HyDialog',
    props: {
        title: {
            type: String,
            default: ''
        },
        width: {
            type: [String, Number],
            default: '50%'
        },
        top: {
            type: String,
            default: '15vh'
        }
    },
    data() {
        return {
            isShow: false
        }
    },
    methods: {
        show() {
            this.isShow = true
        },
        hide() {
            this.isShow = false
        }
    }
}
</script>

<style lang="scss" scoped>
.hy {
    &-tabs {
        height: 28px;
        box-sizing: border-box;
        display: flex;
        position: relative;
        flex-shrink: 0;
    }

    &-tabs::after {
        content: '';
        width: 100%;
        height: 1px;
        background: #4f7ea6;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    &-tabs-item {
        height: 100%;
        padding: 0 18px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #4f7ea6;
        cursor: pointer;

        &__active {
            color: #ffffff;
            border-bottom: 1px solid #61b6fd;
            background: linear-gradient(
                0deg,
                rgba(77, 167, 252, 0.3) 0%,
                rgba(77, 167, 252, 0) 100%
            );
            transition: all 0.2s;
        }
    }
}
</style>
