import hyTabs from './hy-tabs'
import hyDialog from './hy-dialog'

const components = { hyTabs, hyDialog }

export default {
    install(Vue) {
        Object.keys(components).forEach(key => {
            const component = components[key]
            Vue.component(component.name, component)
        })
    },
    ...components
}
