import { Loading } from 'element-ui'

const $loadings = []
const LoadingPlugin = {
    // 自定义Loading
    install(Vue) {
        Vue.mixin({
            methods: {
                $showLoading(refName) {
                    let target = refName ? this.$refs[refName]?.$el : undefined
                    if (target?.className === 'el-dialog__wrapper') {
                        // 如果是弹框，取下级Dom对象
                        target = target.firstChild
                    }
                    const options = {
                        target: target,
                        lock: true,
                        text: '请稍候...',
                        background: 'rgba(255, 255, 255, 0.6)'
                    }
                    const loading = Loading.service(options)
                    $loadings.push(loading)
                    return loading
                },
                $hideLoading(loading) {
                    loading.close()
                    const index = $loadings.findIndex((item) => {
                        return item === loading
                    })
                    if (index !== -1) {
                        $loadings.splice(index, 1)
                    }
                }

            }
        })

        Vue.prototype.$hideAllLoading = function() {
            if ($loadings.length > 0) {
                $loadings.forEach(loading => {
                    loading.close()
                })
                $loadings.splice(0)
            }
        }
    }
}

export default LoadingPlugin
