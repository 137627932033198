import ElementUI from 'element-ui'
import * as echarts from 'echarts'
import Loading from './loading'
import { doRequest, METHOD } from './request'

const Utils = {
    install: function(Vue) {
        Vue.use(ElementUI, { size: 'small' }).use(Loading)

        Vue.prototype.$echarts = echarts

        Vue.prototype.$get = function(url, data, config) {
            return doRequest(url, METHOD.GET, data, config, this)
        }
        Vue.prototype.$post = function(url, data, config) {
            return doRequest(url, METHOD.POST, data || {}, config, this)
        }
        Vue.prototype.$download = function(url, data) {
            return doRequest(url, METHOD.DOWNLOAD, data, {}, this)
        }
        Vue.prototype.$downloadGet = function(url, data) {
            return doRequest(url, METHOD.DOWNLOAD_GET, data, {}, this)
        }
        Vue.prototype.$upload = function(url, data, config) {
            return doRequest(url, METHOD.UPLOAD, data, config, this)
        }

        // 二次确认封装
        Vue.prototype.$doConfirm = function(message, title, options) {
            return new Promise((resolve) => {
                this.$doConfirmAsync(message, title, options).then((isConfirm) => {
                    if (isConfirm) {
                        resolve()
                    }
                })
            })
        }
        Vue.prototype.$doConfirmAsync = async function(message, title, options) {
            return new Promise((resolve) => {
                this.$confirm(message, title || '提示', {
                    ...options,
                    callback: (action) => {
                        if (action === 'confirm') {
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    }
                })
            })
        }

        /**
         * 提示成功信息
         * @param {Object} message
         */
        Vue.prototype.$showSuccess = function(message) {
            message = message || '操作成功'
            this.$message.success({
                showClose: true,
                duration: 2000,
                message: message
            })
        }

        /**
         * 提示错误信息
         * @param {Object} message
         */
        Vue.prototype.$showError = function(message) {
            this.$message.error({
                showClose: true,
                duration: 2000,
                message: message
            })
        }

        /**
         * 提示警告信息
         * @param {Object} message
         */
        Vue.prototype.$showWarning = function(message) {
            this.$message.warning({
                showClose: true,
                duration: 2000,
                message: message
            })
        }
    }
}

export default Utils
