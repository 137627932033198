import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const homepage = () => import('@/views')
const demo = () => import('@/views/demo')

import safeSystemRouter from './modules/safeSystem'
import pushLeadRouter from './modules/pushLead'

const routes = [
    {
        path: '/',
        name: 'homepage',
        component: homepage
        // redirect: '/safeSystemSearch'
    },
    {
        path: '/demo',
        name: 'demo',
        component: demo
    },
    ...safeSystemRouter,
    ...pushLeadRouter
]

const router = new VueRouter({
    routes
})

export default router
